import React, { Component } from 'react'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import GuideSize from 'Components/UI/GuideSize'

class SizeGuide extends Component {
  constructor(props) {
    super(props)
    this.contact = React.createRef()
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Size Guide'
      }
    ]
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <Layout>
        <SeoHead
          title="Size Guide"
          path={this.props.location.pathname}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className='u-height--120' />
        <div className="u-offset-lg-1 u-col-lg-10 u-remove-autofill u-pad-x--lg">
          <GuideSize />
        </div>
        <div className='u-height--120' />
      </Layout>
    )
  }
}

export default SizeGuide
