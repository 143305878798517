import React, { Component } from 'react'
import styles from './index.module.scss'

const sizes = [
  { id: 'stellaluna', title: 'Stella Luna (Italy)', sizes: [36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40] },
  { id: 'us', title: 'US', sizes: [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10] },
  { id: 'france', title: 'France', sizes: [37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41] },
  { id: 'italy', title: 'Italy', sizes: [36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40] },
  { id: 'uk', title: 'UK', sizes: [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7] },
  { id: 'japan', title: 'Japan', sizes: [23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27] }
]

class GuideSize extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const allSizes = sizes.map((size, index) => {
      return (
        <div key={`guide-size-${size.id}-${index}`} className={`${styles.row} u-mrg-b--50 u-col-4 u-col-lg-2`}>
          <div className={`u-separator`}></div>
          <div className={`u-height--small`} />
          <div className={`u-height--small`} />
          <div className={`u-height--10`} />
          <div className={`u-text-center`}>
            <h2 className={`u-text-center t-paragraph-1`}>{size.title}</h2>
            <div className={`u-height--10`} />
            <div className={`u-separator`}></div>
            {
              size.sizes.map((s, b) => {
                return (
                  <div key={`unique-size-${size.id}-${index}-${b}`} className={`${styles.item} u-text-center t-paragraph-2`}>
                    <div className={`u-height--10`} />
                    <div className={`u-height--small`} />
                    <div className={`u-height--small`} />
                    {s}
                    <div className={`u-height--10`} />
                    <div className={`u-separator`}></div>
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    })
    return (
      <div className={`${this.props.className} guide-size u-relative u-user-select--none u-pad-x--lg`}>
        <h1 className={`t-big-title`}>Shoe Size Conversion Chart</h1>
        <div className={`u-height--10`} />
        <p className={`t-paragraph-0 u-col-lg-6`}>Please note: The following information is intended for use as a general guideline.</p>
        <div className={`u-height--40`} />
        <div className={`u-col-lg-12`}>
          <div className={`u-row`}>{allSizes}</div>
        </div>
      </div>
    )
  }
}

GuideSize.defaultProps = {
  className: ''
}

export default GuideSize
